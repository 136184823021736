import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { makeInternalUrlRelative } from '@separate/utilities/urlsProcessing';
import styles from './articleBlock.module.scss';

export default function CallToActionBlock({ data }) {
  const router = useRouter();

  function handleClick() {

    const ctaURL = makeInternalUrlRelative(data.cta_url.href);
    router.push(ctaURL);
  }

  return (
    <div className="text-center py-2 px-3">
      <Button onClick={handleClick} className={styles.articleCta}>{data.cta_url.title}</Button>
    </div>
  );
}

CallToActionBlock.propTypes = {
  data: PropTypes.shape({
    cta_url: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  }),
};
